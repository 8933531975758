import React from 'react';

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions';

import 'materialize-css';
import M from 'materialize-css';
import '../styles/report.scss';

import ReportTest from './report_components/ReportTest';
import ReportNote from './report_components/ReportNote';
import NodeWorkbench from './report_components/NodeWorkbench';
import RecipientSelect from '../components/RecipientSelect';
import PreSubmit from './report_components/PreSubmit';
import SubmitSuccess from './report_components/SubmitSuccess';
import { Checkbox } from 'react-materialize';

import CreateIcon from '@mui/icons-material/Create';
import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Textfit from 'react-textfit';
import EditIcon from '@mui/icons-material/Edit';

import { useState, useEffect, useRef } from 'react';


const ReportEdit = (props) => {
  const [editMetadata, setEditMetadata] = useState(false);
  const [hideSidebar, setHideSidebar] = useState(false);
  const [componentFocused, setComponentFocused] = useState(null);
  const [nodeFocused, setNodeFocused] = useState(null); // used by nodeworkbench
  const [pickersInitialized, setPickersInitialized] = useState(false);
  const [preSubmitShown, setPreSubmitShown] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [headerShown, setHeaderShown] = useState(true);
  const [draggedNode, setDraggedNode] = useState(null);
  const [dragOverNode, setDragOverNode] = useState(null);
  const [tempRecipients, setTempRecipients] = useState(null);
  const [editComponentName, setEditComponentName] = useState({id: null, tempName: ''});

  const scrollPosition = useRef(0);

  useEffect(() => {
    props.showNav(false);
    props.fetchReport(
      props.authToken,
      window.location.search.split('?')[1]
    );
    props.fetchTestFields(props.authToken);
  }, []);

  useEffect(() => {
    if (!props.report) return;
    
    let datepickers = document.querySelectorAll('.datepicker');
    let datepickerOptions = {
      autoClose: true,
      format: 'mmm d, yyyy',
      defaultDate: new Date(props.report.date_of_visit),
      setDefaultDate: true,
      container: document.querySelectorAll('.report-body'),
      maxDate: new Date(),
      onSelect: (d) => props.updateReport({
        id: props.report.id,
        date_of_visit: d.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })
      }, {fridge: true, freezer: props.authToken}, null)
    };
    let dInstance = M.Datepicker.init(datepickers, datepickerOptions)

    let timepickers = document.querySelectorAll('.timepicker');
    let timepickerOptions = {
      autoClose: true,
      container: '.report-body',
      defaultTime: 'now',
      onCloseEnd: () => {
        let times = Array.prototype.slice.call(timepickers).map(picker => {
          return picker.value
        });

        props.updateReport({
          id: props.report.id,
          onsite_start: times[0].replace(/^0+/, ''),
          onsite_end: times[1].replace(/^0+/, '')
        }, {fridge: true, freezer: props.authToken}, null);
      }
    }
    let tInstance = M.Timepicker.init(timepickers, timepickerOptions);
  }, [props.report]);

  const validateResult = (result) => {
    return result?.replace(/[^0-9.]/g,'');
  };

  const handleTitleInteraction = (e) => {
    if (e) {
      e.preventDefault();
      return;
    }

    if (!editTitle) {
      setEditTitle(true);
      setTimeout(() => {
        document.getElementById("report-title-input").focus();
      }, 100);
    } else {
      document.getElementById("report-title-input").blur();
      setEditTitle(false);
    }
  };

  const handleScroll = (e) => {
    const scrollingUp = e.deltaY < 0;
    
    if (Math.abs(e.currentTarget.scrollTop - scrollPosition.current) >= 40) {
      setHeaderShown(scrollingUp);
      scrollPosition.current = e.currentTarget.scrollTop;
    }
  };

  const toggleSidebar = () => {
    setHideSidebar(!hideSidebar);
  };

  const formatLimitsText = (min, max) => {
    if (min && max) {
      return <span className="limits">{` (${min} — ${max})`}</span>
    } else if (min) {
      return <span className="limits">{` (min ${min})`}</span>
    } else if (max) {
      return <span className="limits">{` (max ${max})`}</span>
    } else {
      return null
    }
  };

  const scrollToComponent = (id) => {
    console.log('scrolling to', `comp-${id}`);
    document.getElementById(`comp-${id}`).scrollIntoView({behavior: 'smooth'});
  };

  const groupComponents = () => {
    let compsArray = Object.entries(props.report?.components).map(c => c[1]);
    let compGroups = [];

    for (var i=0; i<compsArray.length; i++) {
      if (compsArray[i].group) {
        if (!compGroups.includes(compsArray[i].group)) {
          compGroups.push(compsArray[i].group)
        }
      }
    }

    return compGroups
  };

  const componentIsVisible = (comp) => {
    let hidden = props.report.hidden_components || [];
    return !hidden.includes(`${comp.id}`);
  };

  const componentGroupIsVisible = (groupName) => {
    let group = Object.entries(props.report.components).map(c => c[1]).filter(c => c.group === groupName);
    return group.filter(c => !componentIsVisible(c)).length < group.length;
  };

  const toggleComponentVisibility = (event, comp, groupName) => {
    event.stopPropagation();

    let hiddenComps = props.report.hidden_components;

    if (groupName) {
      let groupIds = Object.entries(props.report.components).map(c => c[1]).filter(c => c.group === groupName).map(c => `${c.id}`);
      for (var i=0; i<groupIds.length; i++) {
        hiddenComps = hiddenComps.filter(c => !groupIds.includes(c));
        
        if (componentGroupIsVisible(groupName)) {
          hiddenComps = hiddenComps.concat(groupIds);
        }
      }
    } else if (comp) {
      let visible = props.report.hidden_components.includes(`${comp.id}`);
      hiddenComps = visible ? hiddenComps.filter(id => id !== `${comp.id}`) : hiddenComps.concat(`${comp.id}`);
    }

    props.updateReport(
      {id: props.report.id, hidden_components: hiddenComps},
      {fridge: true, freezer: props.authToken},
      null
    );
  };

  const generateAuthorText = () => {
    if (props.report.submitted) {
      let submitDate = new Date('2022-11-30T21:23:46.000Z').toLocaleDateString();
      let submitTime = new Date(props.report.submitted).toLocaleTimeString('en-US', {hour: 'numeric', minute: '2-digit'})

      if (submitDate === new Date().toLocaleDateString()) {
        return `Submitted at ${submitTime} by ${props.report.author}`
      } else {
        return `Submitted on ${submitDate} by ${props.report.author}`
      }
    } else {
      return `Report by ${props.report.author}`
    }
  };

  const getChangedPos = (currentPos, newPos, testId, reportComponent) => {
    console.log('getChangedPos', currentPos, newPos, testId, reportComponent);

    // obtain array of all tests and notes under component
    let compNodes = Object.entries(reportComponent.tests).concat(Object.entries(reportComponent.notes));
    compNodes = compNodes.map(n => ({...n[1]}));

    // filter out nodes that are not within the range of the current and new positions
    compNodes = compNodes.filter((node) => {
      console.log('comparing', node.id, testId);
      if (`${node.id}` === `${testId}`) {
        return true
      } else if (node.position === null) {
        return false
      }
      const withinRange = node.position >= Math.min(currentPos, newPos) && node.position <= Math.max(currentPos, newPos);

      return withinRange;
    });

    console.log('compNodes', compNodes);

    // determine the direction of the shift
    let shift = currentPos > newPos ? 1 : -1; 
  
    // shift the nodes that are within the range
    for (var i = 0; i < compNodes.length; i++) {
      if (`${compNodes[i].id}` === `${testId}`) {
        compNodes[i].position = newPos
        console.log('position updated', compNodes[i].position);
      } else {
        compNodes[i].position += shift;
      }
    }
    
    // build tests and notes back into report scaffold
    let tests = {}
    let notes = {}
    compNodes.map(node => node?.iAm === 'test' ? tests[node.id] = node : notes[node.id] = node)
    let reportScaffold = {
      id: props.report.id,
      components: {
        [reportComponent.id]: {
          tests: tests,
          notes: notes
        }
      }
    }

    // update report with new scaffold
    props.updateReport(
      reportScaffold, 
      {fridge: true, freezer: props.authToken},
      null
    )
  };

  const handleDragStart = (e) => {
    if (e.target.dataset.type === 'pseudonode') {
      e.preventDefault();
      return;
    }
    
    setDraggedNode(e.target);
    e.target.classList.add('dragging');
    e.target.closest('.report-results-container').classList.add('dragging-active');
  };

  const handleDragEnd = (e) => {
    e.target.classList.remove('dragging');
    e.target.closest('.report-results-container').classList.remove('dragging-active');
    
    // Remove any drop indicators
    document.querySelectorAll('.drop-above, .drop-below').forEach(el => {
      el.classList.remove('drop-above', 'drop-below');
    });
    
    setDraggedNode(null);
    setDragOverNode(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    
    const nodeContainer = e.target.closest('.node-container');
    if (!nodeContainer || 
        nodeContainer.dataset.type === 'pseudonode' || 
        nodeContainer.parentElement !== draggedNode?.parentElement) {
      return;
    }

    // Remove previous drop indicators
    document.querySelectorAll('.drop-above, .drop-below').forEach(el => {
      el.classList.remove('drop-above', 'drop-below');
    });

    // Get mouse position relative to the container
    const rect = nodeContainer.getBoundingClientRect();
    const mouseY = e.clientY;
    const threshold = rect.top + (rect.height / 2);
    
    // Add drop indicator above or below based on mouse position
    if (mouseY < threshold) {
      nodeContainer.classList.add('drop-above');
    } else {
      nodeContainer.classList.add('drop-below');
    }

    setDragOverNode(nodeContainer);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    
    if (!draggedNode || !dragOverNode) return; // no node to drag or no node to drop on
    if (draggedNode.dataset.id === dragOverNode.dataset.id) return; // same node, do nothing
    
    // Get positions of dragged and drop nodes  
    const nodes = [...draggedNode.parentElement.getElementsByClassName('node-container')];
    const currentPos = nodes.indexOf(draggedNode);
    const newPos = nodes.indexOf(dragOverNode);
    
    // Get the component and report result IDs
    const resultId = draggedNode.dataset.id;
    const reportComponent = props.report.components[draggedNode.parentElement.dataset.componentId];
    
    // Update positions
    getChangedPos(currentPos, newPos, resultId, reportComponent);
  };

  const renderComponentNodes = (component, keyOnly = false) => {
    if (keyOnly) {
      return Object.entries(component.tests).map(t => t[1].id).join('.');
    } else {
      let tests = component.tests ? Object.entries(component.tests) : [];
      let notes = component.notes ? Object.entries(component.notes) : [];

      let orderedNodes = new Array(tests.length + notes.length);
      let unpositionedNodes = [];

      tests.forEach(t => {
        if (t[1].position != null) {
          orderedNodes[t[1].position] = t;
        } else {
          unpositionedNodes.push(t);
        }
      });

      notes.forEach(n => {
        if (n[1].position != null) {
          orderedNodes[n[1].position] = n;
        } else {
          unpositionedNodes.push(n);
        }
      });

      let unpositionedIndex = 0;
      for (let i = 0; i < orderedNodes.length; i++) {
        if (!orderedNodes[i] && unpositionedIndex < unpositionedNodes.length) {
          orderedNodes[i] = unpositionedNodes[unpositionedIndex++];
        }
      }

      return orderedNodes.map(n => (
        n[1].iAm === 'test' ?
        <ReportTest
          key={n[1].id}
          test={n[1]}
          component={component}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        /> :
        <ReportNote
          key={n[1].id}
          node={n[1]}
          component={component}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          handleClick={() => {setNodeFocused(n[1]); setComponentFocused(component)}}
        />
      ))
    }
  };

  const handleComponentRename = (e) => {
    e.preventDefault();
    props.updateReport({id: props.report.id, components: {[editComponentName.id]: {name: editComponentName.tempName}}}, {fridge: true, freezer: props.authToken}, () => setEditComponentName({id: null, tempName: ''}));
  };

  if (!props.report) {
    return ('loading')
  }

  return (
    <body>
      <div className="app-container">
        <div className={`report-header ${props.report.submitted ? 'submitted' : ''} ${headerShown ? '' : 'hidden'}`}>
          <div className="logo-container" onClick={() => window.location.href="/reports"}></div>
          <div className="header-content">
            <div className='report-title-container' onClick={handleTitleInteraction}>
              {editTitle ? (
                <form onSubmit={handleTitleInteraction}>
                  <input
                    type="number"
                    id="report-title-input"
                    className="title-input"
                    value={props.report?.title}
                    placeholder="Edit Report Title"
                    onChange={(event) => {
                      props.updateReport({title: event.target.value}, {fridge:true})
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onBlur={() => props.updateReport(
                      {id: props.report.id, title: props.report.title}, 
                      {fridge: true, freezer: props.authToken},
                      () => setEditTitle(false)
                    )}
                  />

                  <CheckIcon className="edit-title submit-title" />
                  <button type="submit" style={{display:'none'}}>submit</button>
                </form>
              ) : (
                <span className="report-title-container" style={{display: editTitle ? "none" : "flex"}}>
                  <span className="report-title">
                    {props.report?.title}
                  </span>

                  <CreateIcon className="edit-title" />
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="content-container">
          <div className={`components-sidebar ${hideSidebar ? 'hidden' : ''}`}>

            <div className="sidebar-toggle" onClick={toggleSidebar}>
              <MenuIcon />
            </div>

            <div className="components-container">
              {groupComponents()?.map(group => (
                <div className="component-group" key={group}>
                  <div className={`group-name component ${componentGroupIsVisible(group) ? 'component-visible' : 'component-invisible'}`}>
                    <b><Textfit mode="single" max={22}>{group}</Textfit></b>
                    { componentGroupIsVisible(group) ? 
                      <VisibilityIcon className="visible-indicator" title="Toggle Visibility" onClick={(e) => {toggleComponentVisibility(e, null, group)}}/> : 
                      <VisibilityOffIcon className="invisible-indicator" title="Toggle Visibility" onClick={(e) => {toggleComponentVisibility(e, null, group)}}/>
                    }
                  </div>
                  {Object.entries(props.report.components).filter(c => c[1].group === group).map(c => (
                    <div key={`comp-${c[1].id}`} data-comp={`comp-${c[1].id}`} className={`component travel ${componentIsVisible(c[1]) ? 'component-visible' : 'component-invisible'}`} onClick={() => scrollToComponent(c[1].id)}> 
                      <Textfit mode="single" max={22}>{c[1].name}</Textfit>
                      {
                        componentIsVisible(c[1]) ?
                        <VisibilityIcon className="visible-indicator" title="Toggle Visibility" onClick={(e) => {toggleComponentVisibility(e, c[1])}} /> :
                        <VisibilityOffIcon className="invisible-indicator" title="Toggle Visibility" onClick={(e) => {toggleComponentVisibility(e, c[1])}} />
                      }
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <a className="preview-report-button component" href={props.report.url} target="_blank" rel="noreferrer">
              Preview Report
            </a>
            <button className="recipient-select-button component" onClick={() => setTempRecipients(props.report.recipients)}>
              {props.report.recipients?.length ? `${props.report.recipients?.length} Recipients` : "Select Recipients"}
            </button>
            <button 
              className="submit-button component" 
              disabled={props.report.recipients?.length ? "" : "disabled"}
              onClick={() => setPreSubmitShown(true)}
            >
              Submit Report
            </button>
          </div>

          <div className="report-body" onWheel={handleScroll}>
            {Object.entries(props.report.components).map(c => c[0] !== '-1' ? (
              <div key={`comp-${c[1].id}`} id={`comp-${c[1].id}`} data-component-id={c[1].id} className={`report-results-container ${componentIsVisible(c[1]) ? '' : 'hidden'}`}>
                
                <div className="active-component-header">
                  <div className="header-content">
                    {editComponentName.id === c[1].id ? (
                      <form onSubmit={handleComponentRename}>
                        <input
                        type="text" 
                        className="component-name-input" 
                        value={editComponentName.tempName} 
                        onChange={(e) => setEditComponentName({id: c[1].id, tempName: e.target.value})} 
                        onBlur={(e) => {
                          editComponentName.tempName === c[1].name ? setEditComponentName({id: null, tempName: ''}) : handleComponentRename(e)
                        }}
                        autoFocus/>
                        <button type="submit" style={{display:'none'}}>submit</button>
                      </form>
                    ) : (
                      <span>{c[1].name}</span>
                    )}
                    {c[1].id !== '0' && (
                      <EditIcon className="edit-icon" title="Edit Component Name" 
                        onClick={() => setEditComponentName({id: c[1].id, tempName: c[1].name})}
                      />
                    )}
                  </div>
                </div>

                {renderComponentNodes(c[1])}
                
                <div className="node-container" data-type="pseuonode">
                  <div
                    className="add-node"
                    data-comp={c.id}
                    data-target="new-node"
                    onClick={() => {setComponentFocused(c[1])}}
                  >
                    <AddIcon className="add-icon"/>
                    Add test, note or image
                  </div>
                </div>
              </div>
            ) : null)}

            <div key={`comp--1`} id={`comp--1`} className="report-results-container service-log">
              <div className="active-component-header service-log">
                <div className="header-content">
                  Service Log
                  <VisibilityOffIcon alt="Stuff added here won't be visible in the final report" className="invisible" />
                </div>
              </div>
              <div className="node-container" data-type="pseudonode">
                <div
                  className="add-node"
                  data-comp={'-1'}
                  data-target="new-node"
                  onClick={() => {setComponentFocused(props.report.components['-1'])}}
                >
                  <AddIcon className="add-icon"/>
                  Add test, note or image
                </div>
              </div>
              {Object.entries(props.report.components['-1'].nodes).reverse().map(date => ([
                <span className="log-date">{date[0]}</span>,
                Object.entries(date[1]).map(node => ([
                  node[1].iAm === 'test' ? 
                  <ReportTest 
                    key={node[1].id} 
                    test={node[1]} 
                    component={props.report.components['-1']} 
                    log={true}
                  /> :
                  <ReportNote 
                    key={node[1].id} 
                    node={node[1]} 
                    component={props.report.components['-1']} 
                    handleClick={() => setNodeFocused(node[1], props.report.components['-1'])} 
                    log={true}
                  /> 
                ]))
              ]))}
            </div>

            <div className='report-footer'>
              <div className="report-metadata">
                <div className='report-title-container' onClick={handleTitleInteraction}>
                  {editTitle ? (
                    <form onSubmit={handleTitleInteraction}>
                      <input
                        type="text"
                        id="report-title-input"
                        className="title-input"
                        value={props.report?.title}
                        placeholder="Edit Report Title"
                        onChange={(event) => {
                          props.updateReport({title: event.target.value}, {fridge:true})
                        }}
                        onClick={(e) => e.stopPropagation()}
                        onBlur={() => props.updateReport(
                          {id: props.report.id, title: props.report.title}, 
                          {fridge: true, freezer: props.authToken},
                          () => setEditTitle(false)
                        )}
                      />

                      <CheckIcon className="edit-title submit-title" />
                      <button type="submit" style={{display:'none'}}>submit</button>
                    </form>
                  ) : (
                    <span className="report-title-container" style={{display: editTitle ? "none" : "flex"}}>
                      <span className="report-title">
                        {props.report?.title}
                      </span>

                      <CreateIcon className="edit-title" />
                    </span>
                  )}
                </div>

                <span className="report-author">
                  {generateAuthorText()}
                </span>

                <form className="onsite-form">
                  <div>
                    <span>On site: </span>&nbsp;
                    <input id="onsite-date" className="picker-input datepicker" value={props.report.date_of_visit}></input>
                  </div>

                  <div>
                    <input 
                      id="onsite-start" 
                      className="picker-input timepicker" 
                      value={props.report.onsite_start}
                    /> 
                    &nbsp;—&nbsp;
                    <input 
                      id="onsite-end"
                      className="picker-input timepicker" 
                      value={props.report.onsite_end} 
                      placeholder=" "
                    /> 
                  </div>
                </form>
              </div>
              <div className='halfsize-buttons'>
                <a className='footer-button half preview' href={props.report.url} target="_blank" rel="noreferrer">
                  Preview Report
                </a>
                <button 
                  className='footer-button half recipients'
                  onClick={() => setTempRecipients(props.report.recipients)}
                >
                  Select Recipients</button>
              </div>
              <button 
                className='footer-button submit' 
                onClick={() => setPreSubmitShown(true)}
                // disabled={props.report.recipients?.length ? "" : "disabled"}
              > 
                Submit Report 
              </button>
            </div>

            {/* spacer if window height doesnt take up full device height */}
            {/* <div style="display:flex; flex-grow: 1"></div> */}
            
          </div>
        </div>

        {tempRecipients && (
          <RecipientSelect
            contacts={[...props.report.contacts]}
            selectedRecipients={tempRecipients}
            handleRecipientSelect={email => {
              setTempRecipients(tempRecipients.includes(email) ? 
                tempRecipients.filter(r => r !== email) :
                [...tempRecipients, email]) 
            }}
            addNewContact={contact => setTempRecipients([...tempRecipients, contact])}
            submit={() => {
              // Compare current and new recipients, ignoring order
              const currentSet = new Set(props.report.recipients || []);
              const newSet = new Set(tempRecipients);
              
              if (currentSet.size !== newSet.size || ![...currentSet].every(recipient => newSet.has(recipient))) {
                props.updateReport({
                  id: props.report.id,
                  recipients: tempRecipients
                }, {fridge: true, freezer: props.authToken}, () => setTempRecipients(null));
              }
            }}
            close={() => setTempRecipients(null)}
          />
        )}

        {preSubmitShown && (
          <PreSubmit
            close={() => setPreSubmitShown(false)}
            triggerRecipientSelect={() => setTempRecipients(props.report.recipients)}
            onSuccess={() => setSubmitted(true)}
          />
        )}

        {(componentFocused || nodeFocused) && (
          <NodeWorkbench
            component={componentFocused} 
            node={nodeFocused} 
            switchFocus={(node, comp) => setNodeFocused(node, comp)}
            close={() => setComponentFocused(null, setNodeFocused(null))}
          />
        )}

        {submitted && <SubmitSuccess/>}
      </div>

      {/* <span>{JSON.stringify(componentFocused)}</span> */}
    </body>
  )
};

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    report: state.report,
    user: state.user,
    tests: state.testFields
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportEdit);
