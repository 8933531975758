import { useState, useRef } from "react";
import '../styles/autocomplete.scss';
import { useFuse } from "./useFuse";

// list should be supplied as [{id, label, desc}]

export const Autocomplete = ({ list, onSelect, placeholder }) => {
  const [searchTerm, setText] = useState("");
  const [inputActive, setInputActive] = useState(true);
  const [mouseOverResult, setMouseOverResult] = useState(false);

  const searchResults = useFuse(searchTerm, list);

  const exactMatch = (query, text) => {
    const regex = new RegExp(`^${query}`, 'i');
    // console.log('running exactmatch', query, text, regex.test(text));
    return regex.test(text);
  };

  const handleOptionSelect = option => {
    console.log('handleOptionSelect', option);
    onSelect(option);
    setText(option.label);
    setInputActive(false);
  }

  return (
    <div className={`autocomplete-container ${inputActive ? 'active' : ''}`}>
      <input 
        className={`search-input ${inputActive ? 'active' : ''}`}
        value={searchTerm} 
        onChange={e => setText(e.target.value)}
        placeholder={placeholder || "Enter a search term"} 
        autoFocus
        onClick={e => e.stopPropagation()}
        onFocus={() => setInputActive(true)}
        onBlur={() => mouseOverResult ? null : setInputActive(false)}
      />
      <div className={`results-container ${inputActive ? 'shown' : ''}`}>
        {searchResults.slice(0, 10).map(result => (
          <button 
            class={`${exactMatch(searchTerm, result.label) ? 'exact-match' : ''} search-result`}
            onClick={e => {
              e.stopPropagation();
              handleOptionSelect(result);
            }}
            onMouseOver={() => setMouseOverResult(true)}
            onMouseLeave={() => setMouseOverResult(false)}
          >
            <span>{result.label}</span> 
            <span className="subtext">{result.desc?.length ? ` (${result.desc})` : ''}</span>
          </button>
        ))}
      </div>
    </div>
  );
}

export default Autocomplete;